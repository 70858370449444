<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Maklumat Diri
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Kemaskini maklumat diri</span
        >
      </div>
      <!-- <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Nama Penuh</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="full_name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              disabled
              v-bind:value="currentUser.full_name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >No. Kad Pengenalan</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="nric"
              class="form-control form-control-lg form-control-solid"
              type="text"
              disabled
              v-bind:value="currentUser.nric"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >No. Telefon</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="phone_num"
                class="form-control form-control-lg form-control-solid"
                type="text"
                disabled
                placeholder="Phone"
                v-bind:value="currentUser.phone_num"
              />
            </div>
            <!-- <span class="form-text text-muted"
              >We'll never share your email with anyone else.</span
            > -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >E-mel</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                class="form-control form-control-lg form-control-solid"
                type="text"
                disabled
                placeholder="Email"
                v-bind:value="currentUser.email"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { UPDATE_USER_PROFILE, SET_USER } from "@/core/services/store/user.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "/media/users/default.jpg",
      current_photo: null
    };
  },

  mounted() {
    //nfh-console.log(" IN:MOUNTED")
    //nfh-console.log("OUT:MOUNTED")
  },

  methods: {
    save() {
      var full_name = this.$refs.full_name.value;
      var nric = this.$refs.nric.value;
      var phone_num = this.$refs.phone_num.value;
      var email = this.$refs.email.value;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_USER_PROFILE, {
          full_name: full_name,
          nric: nric,
          phone_num: phone_num,
          email: email,
        }).then((response) => {
          if (response.data.success == true) {
            this.$store.commit(SET_USER, response.data.data);
          }
        })

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      }, 2000);
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserRoles"]),
    photo() {
      return this.default_photo
    },

    isEmailVerified() {
      // xxconsole.log('isEmailVerified:', String(this.currentUser.email_verified_at).trim);
      return String(this.currentUser.email_verified_at).trim() != ""; ;
    },

    isEmailExist() {
      // xxconsole.log('isEmailExist:', String(this.currentUser.email).trim);
      return String(this.currentUser.email).trim() != "";
    },
  }
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom" }, [
    _vm._m(0),
    _c("form", { staticClass: "form" }, [
      _c("div", { staticClass: "card-body" }, [
        _vm._m(1),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "col-xl-3 col-lg-3 col-form-label text-left" },
            [_vm._v("Nama Penuh")]
          ),
          _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
            _c("input", {
              ref: "full_name",
              staticClass: "form-control form-control-lg form-control-solid",
              attrs: { type: "text", disabled: "" },
              domProps: { value: _vm.currentUser.full_name },
            }),
          ]),
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "col-xl-3 col-lg-3 col-form-label text-left" },
            [_vm._v("No. Kad Pengenalan")]
          ),
          _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
            _c("input", {
              ref: "nric",
              staticClass: "form-control form-control-lg form-control-solid",
              attrs: { type: "text", disabled: "" },
              domProps: { value: _vm.currentUser.nric },
            }),
          ]),
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "col-xl-3 col-lg-3 col-form-label text-left" },
            [_vm._v("No. Telefon")]
          ),
          _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
            _c(
              "div",
              { staticClass: "input-group input-group-lg input-group-solid" },
              [
                _vm._m(2),
                _c("input", {
                  ref: "phone_num",
                  staticClass:
                    "form-control form-control-lg form-control-solid",
                  attrs: { type: "text", disabled: "", placeholder: "Phone" },
                  domProps: { value: _vm.currentUser.phone_num },
                }),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            { staticClass: "col-xl-3 col-lg-3 col-form-label text-left" },
            [_vm._v("E-mel")]
          ),
          _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
            _c(
              "div",
              { staticClass: "input-group input-group-lg input-group-solid" },
              [
                _vm._m(3),
                _c("input", {
                  ref: "email",
                  staticClass:
                    "form-control form-control-lg form-control-solid",
                  attrs: { type: "text", disabled: "", placeholder: "Email" },
                  domProps: { value: _vm.currentUser.email },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header py-3" }, [
      _c("div", { staticClass: "card-title align-items-start flex-column" }, [
        _c("h3", { staticClass: "card-label font-weight-bolder text-dark" }, [
          _vm._v(" Maklumat Diri "),
        ]),
        _c(
          "span",
          { staticClass: "text-muted font-weight-bold font-size-sm mt-1" },
          [_vm._v("Kemaskini maklumat diri")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-xl-3" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-phone" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-at" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
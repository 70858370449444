var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-row" }, [
    _c(
      "div",
      {
        staticClass: "flex-row-auto offcanvas-mobile w-300px w-xl-350px",
        attrs: { id: "kt_profile_aside" },
      },
      [
        _c("div", { staticClass: "card card-custom card-stretch" }, [
          _c("div", { staticClass: "card-body pt-4" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-end" },
              [_c("KTDropdown2")],
              1
            ),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center",
                },
                [
                  _c(
                    "div",
                    { staticClass: "symbol-label" },
                    [
                      _c("v-img", {
                        attrs: { src: "/media/users/default.jpg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass:
                      "font-weight-bolder font-size-h5 text-dark-75 text-hover-primary",
                  },
                  [_vm._v(_vm._s(_vm.getFullName))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "py-9" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between mb-2",
                },
                [
                  _c("span", { staticClass: "font-weight-bold mr-2" }, [
                    _vm._v("E-mel:"),
                  ]),
                  _c("a", { staticClass: "text-muted text-hover-primary" }, [
                    _vm._v(_vm._s(_vm.currentUser.email)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between mb-2",
                },
                [
                  _c("span", { staticClass: "font-weight-bold mr-2" }, [
                    _vm._v("No. Telefon:"),
                  ]),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.currentUser.phone_num_display)),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "navi navi-bold navi-hover navi-active navi-link-rounded",
                attrs: { role: "tablist" },
              },
              [
                _c("div", { staticClass: "navi-item mb-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "navi-link py-4",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        "data-tab": "0",
                        "data-toggle": "tab",
                        role: "tab",
                        "aria-selected": "false",
                      },
                      on: { click: _vm.setActiveTab },
                    },
                    [
                      _c("span", { staticClass: "navi-icon mr-2" }),
                      _c("span", { staticClass: "navi-text font-size-lg" }, [
                        _vm._v("Maklumat Diri"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "navi-item mb-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "navi-link py-4",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        "data-tab": "1",
                        "data-toggle": "tab",
                        role: "tab",
                        "aria-selected": "false",
                      },
                      on: { click: _vm.setActiveTab },
                    },
                    [
                      _c("span", { staticClass: "navi-icon mr-2" }),
                      _c("span", { staticClass: "navi-text font-size-lg" }, [
                        _vm._v("Kata Laluan"),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "flex-row-fluid ml-lg-8" },
      [
        _c(
          "b-tabs",
          {
            staticClass: "hide-tabs",
            model: {
              value: _vm.tabIndex,
              callback: function ($$v) {
                _vm.tabIndex = $$v
              },
              expression: "tabIndex",
            },
          },
          [
            _c(
              "b-tab",
              { attrs: { title: "Maklumat Diri", active: "" } },
              [_c("KTPersonalInformation")],
              1
            ),
            _c(
              "b-tab",
              { attrs: { title: "Kata Laluan" } },
              [
                _c("KTChangePassword", {
                  attrs: {
                    currentUser: _vm.currentUser,
                    currentUserRoles: _vm.currentUserRoles,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
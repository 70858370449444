var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      attrs: {
        size: "sm",
        variant: "link",
        "toggle-class":
          "custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon",
        "no-caret": "",
        right: "",
        "no-flip": "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "button-content",
            fn: function () {
              return [_vm._t("default")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "div",
        { staticClass: "navi navi-hover min-w-md-250px" },
        [
          _c(
            "b-dropdown-text",
            {
              staticClass: "navi-header font-weight-bold",
              attrs: { tag: "div" },
            },
            [
              _c("span", { staticClass: "font-size-lg" }, [
                _vm._v(" Choose Label: "),
              ]),
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip",
                    value: "Click to learn more...",
                    expression: "'Click to learn more...'",
                  },
                ],
                staticClass: "flaticon2-information icon-md text-muted",
              }),
            ]
          ),
          _c("b-dropdown-text", {
            staticClass: "navi-separator mb-3 opacity-70",
            attrs: { tag: "div" },
          }),
          _c(
            "b-dropdown-text",
            { staticClass: "navi-item", attrs: { tag: "div" } },
            [
              _c("a", { staticClass: "navi-link" }, [
                _c("span", { staticClass: "navi-text" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "label label-xl label-inline label-light-success",
                    },
                    [_vm._v(" Customer ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-dropdown-text",
            { staticClass: "navi-item", attrs: { tag: "div" } },
            [
              _c("a", { staticClass: "navi-link" }, [
                _c("span", { staticClass: "navi-text" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "label label-xl label-inline label-light-danger",
                    },
                    [_vm._v(" Partner ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-dropdown-text",
            { staticClass: "navi-item", attrs: { tag: "div" } },
            [
              _c("a", { staticClass: "navi-link" }, [
                _c("span", { staticClass: "navi-text" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "label label-xl label-inline label-light-warning",
                    },
                    [_vm._v(" Suplier ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-dropdown-text",
            { staticClass: "navi-item", attrs: { tag: "div" } },
            [
              _c("a", { staticClass: "navi-link" }, [
                _c("span", { staticClass: "navi-text" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "label label-xl label-inline label-light-primary",
                    },
                    [_vm._v(" Member ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-dropdown-text",
            { staticClass: "navi-item", attrs: { tag: "div" } },
            [
              _c("a", { staticClass: "navi-link" }, [
                _c("span", { staticClass: "navi-text" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "label label-xl label-inline label-light-dark",
                    },
                    [_vm._v(" Staff ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c("b-dropdown-text", {
            staticClass: "navi-separator mt-3 opacity-70",
            attrs: { tag: "div" },
          }),
          _c(
            "b-dropdown-text",
            { staticClass: "navi-footer pt-5 pb-4", attrs: { tag: "div" } },
            [
              _c(
                "a",
                { staticClass: "btn btn-clean font-weight-bold btn-sm" },
                [
                  _c("i", { staticClass: "ki ki-plus icon-sm" }),
                  _vm._v("Add new"),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }